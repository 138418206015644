import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { styled } from "@mui/material/styles";
import { handleAddUrl } from "../HomePage/handlers/urlManipulation";
import { useEventContext } from "../HomePage/UserDataContext";
import moment from "moment-timezone";

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
  },
});

const AddEventButton = styled("button")(({ theme }) => ({
  background: "transparent",
  border: "none",
  color: theme.palette.primary.main,
  padding: "4px 8px",
  fontSize: "0.875rem",
  fontWeight: 500,
  textTransform: "uppercase",
  cursor: "pointer",
  transition: "all 0.2s ease",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "2px",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    transform: "scaleX(0)",
    transformOrigin: "bottom right",
    transition: "transform 0.3s ease",
  },
  "&:hover": {
    color: theme.palette.primary.dark,
    "&:after": {
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  tabs: {
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, .1)",
  },
  viewSelect: {
    minWidth: 120,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  eventName: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  eventInfo: {
    marginBottom: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const convertDatesToLocalTimezone = (data) => {
  const userTimezone = moment.tz.guess();

  Object.keys(data).forEach((timeSlot) => {
    data[timeSlot].forEach((event) => {
      if (event.d && event.d !== "TBD") {
        const utcDate = moment.tz(event.d, "ddd, MMMM D, YYYY h:mm A", "UTC");

        const localDate = utcDate.clone().tz(userTimezone);

        event.d = localDate.format("ddd, MMMM D, YYYY h:mm A");
      }
    });
  });

  return data;
};

const generateTimeSlots = (data) => {
  const userTimezone = moment.tz.guess();

  const timeSlots = Object.keys(data).map((time) => {
    const originalTime = moment.tz(time, "HH:mm", "UTC");
    const userTime = originalTime.clone().tz(userTimezone);
    let formattedTime = userTime.format("h:mm A");

    return {
      original: time,
      formatted: formattedTime,
      timestamp: userTime.toISOString(),
    };
  });

  const sortedTimeSlots = timeSlots.sort((a, b) => {
    const timeA = moment(a.formatted, "h:mm A");
    const timeB = moment(b.formatted, "h:mm A");
    return timeA.valueOf() - timeB.valueOf();
  });

  return sortedTimeSlots;
};

const SalesPage = () => {
  const classes = useStyles();
  const [timeSlot, setTimeSlot] = useState("15:00");
  const [data, setData] = useState({});
  const [view, setView] = useState("table");
  const [salesDay, setSalesDay] = useState(0);

  const userContext = useEventContext();
  const { urls, TotalUrls, mainUser } = userContext;

  useEffect(() => {
    const fetchData = async () => {
      const calculateSalesDate = (daysOffset) => {
        const timezone = moment.tz.guess();
        return moment().tz(timezone).startOf("day").add(daysOffset, "days");
      };

      const targetSalesDay = calculateSalesDate(salesDay);
      const salesCollection = collection(db, "OnSale2");

      const qTm = query(
        salesCollection,
        where("salesDay", "==", targetSalesDay.toDate()),
        where("eventType", "==", "tm"),
        orderBy("date", "desc"),
        limit(1)
      );

      const qAxs = query(
        salesCollection,
        where("salesDay", "==", targetSalesDay.toDate()),
        where("eventType", "==", "axs"),
        orderBy("date", "desc"),
        limit(1)
      );

      const [tmSnapshot, axsSnapshot] = await Promise.all([
        getDocs(qTm),
        getDocs(qAxs),
      ]);

      let tmData = null;
      let axsData = null;
      tmSnapshot.forEach((doc) => {
        tmData = doc.data().data;
      });
      axsSnapshot.forEach((doc) => {
        axsData = doc.data().data;
      });

      let combinedData = {};

      for (let key in tmData) {
        combinedData[key] = tmData[key];
      }

      for (let key in axsData) {
        if (combinedData[key]) {
          combinedData[key] = [...combinedData[key], ...axsData[key]];
        } else {
          combinedData[key] = axsData[key];
        }
      }

      const convertedData = convertDatesToLocalTimezone(combinedData);
      const sortedData = sortDataByTime(convertedData);

      setData(sortedData);
    };

    fetchData();
  }, [salesDay]);

  const sortDataByTime = (data) => {
    const timeOrder = [
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
      "00:00",
    ];
    const sortedData = {};
    timeOrder.forEach((time) => {
      if (data[time] && data[time].length > 0) {
        sortedData[time] = data[time].sort((a, b) => {
          if (a.n !== b.n) {
            return a.n.localeCompare(b.n);
          }
          return a.d.localeCompare(b.d);
        });
      }
    });
    return sortedData;
  };

  const handleTimeSlotChange = (event, newValue) => {
    setTimeSlot(newValue);
  };

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleSalesDayChange = (event) => {
    setSalesDay(event.target.value);
  };

  const timeSlots2 = generateTimeSlots(data);

  if (
    auth.currentUser.email !== "test@gmail.com" &&
    auth.currentUser.email !== "dcheck1826@gmail.com"
  ) {
    return null;
  }

  const renderEventCard = (event) => (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" className={classes.eventName}>
          {event.n}
        </Typography>
        <Typography className={classes.eventInfo}>Venue: {event.v}</Typography>
        <Typography className={classes.eventInfo}>
          Capacity: {event.c}
        </Typography>
        <Typography className={classes.eventInfo}>Date: {event.d}</Typography>
        <Typography className={classes.eventInfo}>
          Offer: {event.offer.map((o) => o).join(", ")}
        </Typography>
        <Typography className={classes.eventInfo}>
          Price Range: {event.priceRange}
        </Typography>
        <Typography>
          <a
            href={event.u}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Event Link
          </a>
        </Typography>
      </CardContent>
    </Card>
  );

  const renderContent = () => {
    switch (view) {
      case "table":
        return (
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Venue</TableCell>
                  <TableCell>Capacity</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Offer</TableCell>
                  <TableCell>Price Range</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Add Event</TableCell>
                  <TableCell>Add Artist</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[timeSlot] &&
                  data[timeSlot].map((event, index) => (
                    <TableRow key={index}>
                      <TableCell>{event.n}</TableCell>
                      <TableCell>{event.v}</TableCell>
                      <TableCell>{event.c}</TableCell>
                      <TableCell>{event.d}</TableCell>
                      <TableCell>{event.o.map((o) => o).join(", ")}</TableCell>
                      <TableCell>{event.p}</TableCell>
                      <TableCell>
                        <a // eslint-disable-line jsx-a11y/anchor-is-valid, no-script-url
                          href="#"
                          target="_blank"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(event.u, "_blank");
                          }}
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          {event.e}
                        </a>
                      </TableCell>
                      <TableCell>
                        <AddEventButton
                          disabled={urls.includes(event.u)}
                          style={{
                            cursor: urls.includes(event.u)
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={() => {
                            handleAddUrl(
                              "",
                              [event.u],
                              urls,
                              TotalUrls,
                              undefined,
                              mainUser,
                              db
                            );
                          }}
                        >
                          Add Event
                        </AddEventButton>
                      </TableCell>
                      <TableCell>
                        <AddEventButton
                          onClick={() => {
                            if (event.a[0] === "") {
                              return;
                            }

                            handleAddUrl(
                              "",
                              event.a,
                              urls,
                              TotalUrls,
                              undefined,
                              mainUser,
                              db
                            );
                          }}
                        >
                          Add Artist
                        </AddEventButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case "grid":
        return (
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            {data[timeSlot] &&
              data[timeSlot].map((event, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  {renderEventCard(event)}
                </Grid>
              ))}
            k
          </Grid>
        );
      case "list":
        return (
          <List style={{ marginTop: "20px" }}>
            {data[timeSlot] &&
              data[timeSlot].map((event, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={event.n}
                    secondary={`${event.v} - ${event.d}`}
                  />
                </ListItem>
              ))}
          </List>
        );
      case "all":
        return (
          <Box style={{ marginTop: "20px" }}>
            {timeSlots2.map((slot) => (
              <Box key={slot.original} mb={4}>
                <Typography variant="h5" className={classes.header}>
                  {slot.formatted}
                </Typography>
                <Grid container spacing={3}>
                  {data[slot.original] &&
                    data[slot.original].map((event, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        {renderEventCard(event)}
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}

            {/* {timeSlots.map((slot) => (
              <Box key={slot} mb={4}>
                <Typography variant="h5" className={classes.header}>
                  {slot}
                </Typography>
                <Grid container spacing={3}>
                  {data[slot] &&
                    data[slot].map((event, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        {renderEventCard(event)}
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))} */}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.header} gutterBottom>
          Sales Data
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Box mt={2} mb={2}>
            <FormControl variant="outlined" className={classes.viewSelect}>
              <InputLabel id="view-select-label">View</InputLabel>
              <Select
                labelId="view-select-label"
                value={view}
                onChange={handleViewChange}
                label="View"
              >
                <MenuItem value="table">Table</MenuItem>
                <MenuItem value="grid">Grid</MenuItem>
                <MenuItem value="list">List</MenuItem>
                <MenuItem value="all">All Times</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl variant="outlined" className={classes.viewSelect}>
              <InputLabel id="sales-day-select-label">Sales Day</InputLabel>
              <Select
                labelId="sales-day-select-label"
                value={salesDay}
                onChange={handleSalesDayChange}
                label="Sales Day"
              >
                {Array.from({ length: 7 }, (_, i) => (
                  <MenuItem key={i} value={i}>
                    {i === 0 ? "Today" : `In ${i} days`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Tabs
          value={timeSlot}
          onChange={handleTimeSlotChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className={classes.tabs}
        >
          {timeSlots2.map((slot) => (
            <Tab
              key={slot.original}
              label={slot.formatted}
              value={slot.original}
            />
          ))}
        </Tabs>

        {renderContent()}
      </Container>
    </ThemeProvider>
  );
};

export default SalesPage;
