import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Grid, Button, ListItemText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMapImage } from '../../../hooks/useMapImage';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EmailContent from './generateHtml';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: ({ darkMode }) => darkMode ? '#121212' : '#FFFFFF',
    color: ({ darkMode }) => darkMode ? '#E7E7E7' : '#3C3C3C',
    borderRadius: 16,
    maxWidth: 1000,
    width: '100%',
    margin: 'auto',
    marginBottom: theme.spacing(3),
    border: ({ darkMode }) => darkMode ? '1px solid #7E7E7E' : '1px solid #DBDBDB',
    fontFamily: "'Inter', sans-serif",
    padding: '2px',
  },
  content: {
    padding: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontFamily: "'Inter', sans-serif",
    color: ({ darkMode }) => darkMode ? '#E7E7E7' : '#3C3C3C',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.8),
    fontFamily: "'Inter', sans-serif",
    color: ({ darkMode }) => darkMode ? '#E7E7E7' : '#3C3C3C',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: ({ darkMode }) => darkMode ? '#E7E7E7' : '#3C3C3C',
  },
  sectionTitle: {
    color: '#0094FF',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontFamily: "'Inter', sans-serif",
  },
  phantomTitle: {
    color: '#6C3D8A',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontFamily: "'Inter', sans-serif",
  },
  releaseTime: {
    color: theme.palette.type === 'dark' ? '#E7E7E7' : '#000000',
    fontSize: 14,
    textAlign: 'left',
    fontFamily: "'Inter', sans-serif",
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  rightColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '-20px',
    marginBottom: theme.spacing(2),
    userSelect: 'none',
  },
  mapImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    borderRadius: '10px',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  marketplaceButton: {
    width: '68px',
    height: '35px',
    borderRadius: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(-2),
  },
  qPing: {
    background: "linear-gradient(90deg, rgb(215, 174, 251) 0%, rgb(142, 68, 173) 100%)",
    borderRadius: "8px",
    marginBottom: "8px",
    padding: "16px",
    minHeight: '120px',
    "& .MuiListItemText-primary": {
      color: "black",
      fontFamily: "'Inter', sans-serif",
    }
  },
  qPingDarkMode: {
    background: "linear-gradient(90deg, rgb(215, 174, 251) 0%, rgb(142, 68, 173) 100%)",
    borderRadius: "8px",
    marginBottom: "8px",
    padding: "16px",
    minHeight: '120px',
    "& .MuiListItemText-primary": {
      color: "black",
      fontFamily: "'Inter', sans-serif",
    }
  },
  standardAdmissionContainer: {
    borderRadius: '8px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    transition: 'background-color 0.2s ease-in-out, border 0.2s ease-in-out',
    cursor: 'pointer',
    display: 'inline-block',
    minWidth: 'min-content',
    maxWidth: '100%',
  },
  standardAdmissionDark: {
    backgroundColor: '#5252524D',
    border: '1px solid #5E5E5E',
    '&:hover': {
      backgroundColor: '#0044744D',
      border: '1px solid #004679',
    },
  },
  standardAdmissionLight: {
    backgroundColor: '#52525233',
    border: '1px solid #5E5E5E66',
    '&:hover': {
      backgroundColor: '#00447426',
      border: '1px solid #00467940',
    },
  },
}));

const getStubhubLevelPrices = (stubhubData) => {
  if (!stubhubData?.total_data) return null;
  
  const levelPrices = {};
  
  for (const category in stubhubData.total_data) {
    const sections = stubhubData.total_data[category];
    const minArray = sections
      .map((section) => {
        const minValue = parseFloat(section.min?.replace(/[$,]/g, ""));
        return !isNaN(minValue) && minValue !== 0 ? minValue : null;
      })
      .filter((min) => min !== null);

    if (minArray.length > 0) {
      levelPrices[category] = `$${Math.min(...minArray)}`;
    }
  }
  
  return levelPrices;
};

const EventDetailCard = React.memo(({ 
  event, 
  darkMode, 
  isLowStock,
  handleOverlayOpen,
  handleOverlayClose,
  filtersDocument,
  notesDocument,
  mainUser,
  early,
  totalEarly,
  vividIds,
  handleFilterClick,
  setCurrentEventId,
  setNotesModalOpen
}) => {
  const [localOverlayOpen, setLocalOverlayOpen] = useState(false);
  const classes = useStyles({ darkMode });
  const { mapImage, isLoading, retry } = useMapImage(event.map_url, event.eventLink);
  const theme = useTheme();

  const isMlb = event.eventLink?.includes('mlb.tickets.com');
  const isAxs = event.eventLink?.includes('axs.com');
  const isQueueOpened = event.qPing;

  const handleLocalOverlayOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLocalOverlayOpen(true);
  };

  const handleLocalOverlayClose = () => {
    setLocalOverlayOpen(false);
  };

  const handleCardClick = (e) => {
    e.preventDefault();
    if (!localOverlayOpen) {
      window.open(event.eventLink, '_blank');
    }
  };

  return (
    <Card 
      className={`${
        event.qPing
          ? darkMode
            ? classes.qPingDarkMode
            : classes.qPing
          : darkMode 
            ? classes.card 
            : classes.card
      }`}
      style={{
        backgroundColor: event.qPing ? 'transparent' : (darkMode ? '#121212' : '#FFFFFF'),
        color: event.qPing ? 'black' : (darkMode ? '#E7E7E7' : '#3C3C3C'),
        border: darkMode ? '1px solid #7E7E7E' : '1px solid #DBDBDB',
        cursor: localOverlayOpen ? 'default' : 'pointer',
        margin: '10px 0',
        padding: '4px',
        position: 'relative'
      }}
      onClick={handleCardClick}
    >
      {event.qPing ? (
        <>
          <ListItemText
            style={{ paddingLeft: '16px', paddingTop: '12px' }}
            primary={
              <>
                {`${event.name} - ${event.venue}, ${event.date} `}
                <br />
                <div style={{ marginTop: '8px'}}>
                  <img
                    src="/Queue-logo.png"
                    alt="Q-Tickets"
                    style={{
                      height: "25px",
                      width: "auto",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline-block",
                    }}
                  />
                  <strong>QUEUE OPENED</strong>
                  <img
                    src="/Queue-logo.png"
                    alt="Q-Tickets"
                    style={{
                      height: "25px",
                      width: "auto",
                      verticalAlign: "middle",
                      marginLeft: "10px",
                      display: "inline-block",
                    }}
                  />
                </div>
                <Typography 
                  variant="caption" 
                  component="div"
                  style={{ 
                    color: 'black',
                    fontSize: '12px',
                    fontFamily: "'Inter', sans-serif",
                    marginTop: '8px'
                  }}
                >
                  <strong>Release Time:</strong> {event.releaseTime}
                </Typography>
              </>
            }
          />
        </>
      ) : (
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography 
                variant="h5" 
                className={classes.title}
                style={{ 
                  color: isQueueOpened ? 'black' : darkMode ? '#E7E7E7' : '#3C3C3C'
                }}
              >
                {isQueueOpened ? (
                  <>
                    {`${event.name} - ${event.venue}, ${event.date} `}
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                      <img
                        src="/Queue-logo.png"
                        alt="Q-Tickets"
                        style={{
                          height: "25px",
                          width: "auto",
                          marginRight: "10px"
                        }}
                      />
                      <strong>QUEUE OPENED</strong>
                      <img
                        src="/Queue-logo.png"
                        alt="Q-Tickets"
                        style={{
                          height: "25px",
                          width: "auto",
                          marginLeft: "10px"
                        }}
                      />
                    </div>
                  </>
                ) : (
                  event.name
                )}
              </Typography>
              <Typography 
                variant="subtitle1" 
                className={classes.subtitle}
                style={{ color: darkMode ? '#E7E7E7' : '#3C3C3C' }}
              >
                <img src={darkMode ? "/pin1.svg" : "/pin2.svg"} alt="Location Icon" className={classes.icon} />
                {event.venue}
              </Typography>
              <Typography 
                variant="subtitle1" 
                className={classes.subtitle}
                style={{ color: darkMode ? '#E7E7E7' : '#3C3C3C' }}
              >
                <img src={darkMode ? "/clock1.svg" : "/clock2.svg"} alt="Time Icon" className={classes.icon} />
                {event.date}
              </Typography>
              
              <div 
                className={`${classes.standardAdmissionContainer} ${
                  darkMode ? classes.standardAdmissionDark : classes.standardAdmissionLight
                }`}
                onClick={handleLocalOverlayOpen}
                role="button"
                tabIndex={0}
              >
                <Typography 
                  variant="h6" 
                  className={classes.sectionTitle}
                  style={{ color: '#0094FF', marginTop: 0 }}
                >
                  Standard Admission
                </Typography>
                {isLowStock ? (
                  <Typography 
                    variant="body1"
                    style={{ 
                      color: darkMode ? '#E7E7E7' : '#000000',
                      marginBottom: theme.spacing(1)
                    }}
                  >
                    {Array.isArray(event.groupTickets) && event.groupTickets.map((ticket, index) => (
                      <div key={index} style={{ marginBottom: '8px' }}>
                        Section {ticket.sectionName} - {ticket.totalAvailableStock}
                      </div>
                    ))}
                  </Typography>
                ) : (
                  Array.isArray(event.groupTickets) ? (
                    event.groupTickets.slice(0, 2).map((ticket, index) => (
                      <Typography 
                        key={index}
                        variant="body1"
                        style={{ 
                          color: darkMode ? '#E7E7E7' : '#000000',
                          marginBottom: theme.spacing(1)
                        }}
                      >
                        Section {ticket.sectionName}, Row {ticket.sectionRow}, 
                        Seats {ticket.seatNumbers ? ticket.seatNumbers.join(', ') : 'N/A'} {' '}
                        <strong>({ticket.seatNumbers ? ticket.seatNumbers.length : ticket.quantity})</strong> {' - '}
                        {event.priceDrop || event.priceIncrease ? (
                          `$${ticket.previousPrice}➜${ticket.price}`
                        ) : (
                          `$${ticket.price}`
                        )}
                      </Typography>
                    ))
                  ) : (
                    <Typography 
                      variant="body1"
                      style={{ 
                        color: darkMode ? '#E7E7E7' : '#000000',
                        marginBottom: theme.spacing(1)
                      }}
                    >
                      Section {event.section}, Row {event.row}, 
                      Seats {event.seats} {' '}<strong>({event.quantity})</strong> {' - '}
                      {event.priceDrop || event.priceIncrease ? (
                        `$${event.previousPrice}->${event.price}`
                      ) : (
                        `$${event.price}`
                      )}
                    </Typography>
                  )
                )}
                {Array.isArray(event.groupTickets) && event.groupTickets.length > 2 && (
                  <Typography 
                    variant="body1"
                    style={{ 
                      color: darkMode ? '#E7E7E7' : '#000000',
                      marginBottom: theme.spacing(1)
                    }}
                  >
                    ...
                  </Typography>
                )}
              </div>
              {event.stubhubId && event.stubhubData && (
                <>
                  <Typography 
                    variant="h6" 
                    className={classes.phantomTitle}
                    style={{ color: '#6C3D8A' }}
                  >
                    Phantom Data
                  </Typography>
                  <Box mt={1} style={{ lineHeight: '1.75' }}>
                    <Typography 
                      variant="body1"
                      style={{ 
                        color: darkMode ? '#E7E7E7' : '#000000',
                      }}
                    >
                      Get In: ${(event.phantomGetIn || 'N/A').toString().replace('$', '')}
                    </Typography>
                    <Typography 
                      variant="body1"
                      style={{ 
                        color: darkMode ? '#E7E7E7' : '#000000',
                      }}
                    >
                      {Object.entries(getStubhubLevelPrices(event.stubhubData) || {})
                        .map(([level, price]) => `${level.replace('Level', 'Lvl')}: ${price}`)
                        .join(', ')}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Box className={classes.rightColumnContainer}>
                <Box className={classes.mapContainer}>
                  {isMlb ? (
                    <img
                      src="/mlb.png"
                      alt="MLB Map"
                      className={classes.mapImage}
                    />
                  ) : isAxs ? (
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Axs_logo.svg"
                      alt="AXS Logo"
                      className={classes.mapImage}
                      style={{
                        height: "180px",
                        width: "180px",
                        margin: "auto"
                      }}
                    />
                  ) : mapImage && typeof mapImage === "string" && mapImage.trim() !== "" ? (
                    <img
                      src={`data:image/png;base64,${mapImage}`}
                      alt="Event Map"
                      className={classes.mapImage}
                      onError={(e) => {
                        console.error("Image failed to load:", e.target.src);
                        e.target.onerror = null;
                        e.target.src = "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                      }}
                    />
                  ) : mapImage !== null ? (
                    <img
                      src={event.map_url}
                      alt="Event Map"
                      className={classes.mapImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "390px",
                        height: "290px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {isLoading ? (
                        <Typography>Loading map...</Typography>
                      ) : (
                        <div>
                          <Typography>Map not available</Typography>
                          <Button 
                            onClick={(e) => {
                              e.stopPropagation();
                              retry();
                            }}
                            size="small"
                          >
                            Retry Loading Map
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
                <Box className={classes.buttonContainer}>
                  {event.stubhubId && (
                    <a
                      href={`https://www.stubhub.com/event/${event.stubhubId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.marketplaceButton}
                      style={{ backgroundColor: '#3F1D74' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        src="/stubhubsmall.svg"
                        alt="stubhub-seats-logo"
                        width="20px"
                        height="19px"
                      />
                    </a>
                  )}
                  {event.vividUrl && (
                    <a
                      href={event.vividUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.marketplaceButton}
                      style={{
                        backgroundImage: darkMode ? 'url(/vivid-blue.svg)' : 'none',
                        backgroundSize: 'cover',
                        backgroundColor: darkMode ? 'transparent' : '#000000',
                        width: '68px',
                        height: '35px'
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        src="/vividsmall.svg"
                        alt="vivid-seats-logo"
                        width="20px"
                        height="17px"
                      />
                    </a>
                  )}
                </Box>
                <Typography 
                  variant="caption" 
                  className={classes.releaseTime}
                  style={{ color: darkMode ? '#E7E7E7' : '#000000' }}
                >
                  <strong>Release Time:</strong> {event.releaseTime}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      )}
      <Dialog
        open={localOverlayOpen}
        onClose={handleLocalOverlayClose}
        scroll="paper"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            maxHeight: "100%",
            width: "90%",
            borderRadius: "20px",
            backgroundColor: darkMode ? "#222222" : "",
            color: darkMode ? "white" : "",
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogContent
          dividers
          style={{
            backgroundColor: darkMode ? "#222222" : "white",
          }}
        >
          <EmailContent
            event_info={{
              name: event.name,
              venue: event.venue,
              date: event.date,
              faceValueExchange: event.faceValueExchange,
              priceRange: event.priceRange
            }}
            quantity={event.quantity}
            map_url={event.map_url}
            groupedTickets={event.groupTickets}
            eventLink={event.eventLink}
            timestamp={event.timestamp}
            filtersDocument={filtersDocument[event.id]}
            handleFilterClick={handleFilterClick}
            notesDocument={notesDocument}
            userEmail={mainUser}
            totalSeats={event.totalSeats}
            stubhub={event.stubhubId}
            eventId={event.id}
            early={early}
            totalEarly={totalEarly}
            showTable={true}
            editFilters={false}
            isLowStock={isLowStock}
            vividIds={vividIds}
            email={{
              ...event,
              ticketMasterUK: event.ticketMasterUK,
              eu: event.eu,
              eventId: event.id,
              eventUrl: event.eventLink,
              priceDrop: event.priceDrop,
              previousPrice: event.previousPrice,
              price: event.price,
              quantity: event.quantity,
              totalSeats: event.totalSeats,
              priceDrops: event.priceDrop
            }}
            setCurrentEventId={setCurrentEventId}
            setNotesModalOpen={setNotesModalOpen}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.event.uniqueId === nextProps.event.uniqueId &&
    prevProps.darkMode === nextProps.darkMode &&
    prevProps.isLowStock === nextProps.isLowStock
  );
});

export default EventDetailCard;
